import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Flex,
  Cell,
  Title,
  Content,
  Button,
  Divider,
  Element,
  Detail,
} from '@remark/components';
import styles from './tags.module.css';

const Tags = ({ title, description, search, details, button, showAmount }) => {
  // State: Initial array of objects that has the 'details' prop as starting point.
  const [defaultData] = useState(details || []);
  // State: Data array of objects that is used as ending point.
  const [data, setData] = useState([]);
  // State: Initial empty string that is used as starting point for the search input.
  const [searchInput, setSearchInput] = useState('');
  // State: Toggle that shows or hides remaining objects.
  const [active, setActive] = useState(false);

  // Function: Puts the typed search value into the searchInput state.
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  // Const array: Returns a new filtered array using the defaultData state.
  // With only objects, where a label text value (item.label.text) matches with the typed search value (searchInput).
  const filteredData = defaultData?.filter((item) =>
    item.label.text.toLowerCase().includes(searchInput.toLowerCase())
  );

  // Lifecycle Method: Putting the new filtered data in the data state, only when searchInput state changes.
  useEffect(() => {
    setData(filteredData);
  }, [searchInput]);

  return (
    <Container full color={'stable-100'} className={styles._wrapper}>
      {(title || description || search || details || button) && (
        <Flex gutter={false}>
          <Cell>
            {title && <Title size={'h5'} {...title} />}
            {title && description && (
              <Divider height={5} color={'transparent'} />
            )}
            {description && (
              <Content size={'label'} color={'stable-900'} {...description} />
            )}
            {!search && <Divider height={20} color={'transparent'} />}
            {search && (
              <Element
                value={searchInput}
                onChange={handleSearch}
                type={'search'}
                className={styles.search}
                {...search}
              />
            )}
            {details && (
              <>
                {data
                  .slice(
                    0,
                    active
                      ? defaultData.length
                      : !button
                      ? defaultData.length
                      : showAmount
                  )
                  .map((item, index) => (
                    <React.Fragment key={index}>
                      {index !== 0 && (
                        <Divider height={10} color={'transparent'} />
                      )}
                      <Detail {...item} />
                    </React.Fragment>
                  ))}
              </>
            )}
            {details && button && <Divider height={20} color={'transparent'} />}
            {button &&
              defaultData.length > showAmount &&
              searchInput === '' && (
                <Button
                  action={() => setActive(!active)}
                  text={
                    active
                      ? i18n.t('merge_tags.button_hide_text')
                      : i18n.t('merge_tags.button_show_text')
                  }
                  link
                  underline
                  formatting={'important'}
                  color={'brave-500'}
                  {...button}
                />
              )}
          </Cell>
        </Flex>
      )}
    </Container>
  );
};

Tags.propTypes = {
  /** The title for the tags */
  title: PropTypes.shape(Title.propTypes),
  /** The description for the tags */
  description: PropTypes.shape(Content.propTypes),
  /** The search input to find specific tags */
  search: PropTypes.shape(Element.propTypes),
  /** The details array contains all the tags, where each tag is displayed as a 'detail' */
  details: PropTypes.arrayOf(PropTypes.shape(Detail.propTypes)).isRequired,
  /** The button action */
  button: PropTypes.oneOfType([
    PropTypes.shape(Button.propTypes),
    PropTypes.bool,
  ]),
  /** The default amount of details visible */
  showAmount: PropTypes.number,
};

Tags.defaultProps = {
  title: null,
  description: null,
  search: null,
  button: null || true,
  showAmount: 9,
};

// Needed for Storybook
Tags.displayName = 'Tags';

export default Tags;
