// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import '@remark/components/build/main.css';
import '../components/styles/index.scss';
import * as Components from '@remark/components';

Rails.start();
Turbolinks.start();
ActiveStorage.start();
/* Register module components */
const app = (window.app = global.app = {});
Object.keys(Components).forEach((component) => {
  if (Components[component].displayName) {
    app[Components[component].displayName] = Components[component];
  }
});

/* Register local components */
var componentRequireContext = require.context('components', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

document.addEventListener('turbolinks:load', function () {
  showHideCoverFields();
  scrollToHash();
});

/* Register fonts */
document.addEventListener('DOMContentLoaded', () => {
  if (Components._breakpointsInit) {
    Components._breakpointsInit();
  }
  if (Components._webFontLoader) {
    Components._webFontLoader('google', null, [
      'Montserrat:400,500,700',
      'Roboto:400,500,700',
    ]);
  }
});

window.showHideCoverFields = function () {
  if (document.querySelectorAll('[data-benefit-type]').length == 0) {
    return;
  }

  var items = Array.from(
    document.querySelectorAll('[data-benefit-type]')
  ).filter(function (elem) {
    var style = window.getComputedStyle(elem);
    return elem.style.display != 'none';
  });

  items = items.map(function (elem) {
    return elem.dataset.benefitType;
  });

  ['DailyAllowanceCover', 'CapitalAmountCover'].forEach(function (type) {
    var elem = document.querySelector(
      '[data-benefit-type-field="' + type + '"]'
    );
    if (items.indexOf(type) == -1) {
      elem.style.display = 'none';
      elem.querySelector('input').value = '';
    } else {
      elem.style.display = 'block';
    }
  });
};

window.scrollToHash = function () {
  if (window.location.hash) {
    document.getElementById(window.location.hash.replace('#','')).scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }
}

window.launchModal = function (content, closeModals = true) {
  if (closeModals && document.querySelector('.portal-node')) {
    document.querySelector('.portal-node').remove();
  }

  const container = document.createElement('div');
  container.innerHTML = content;
  document.body.appendChild(container);

  ReactRailsUJS.mountComponents(container);
  Rails.refreshCSRFTokens();
};

window.getScript = (source, callback) => {
  var script = document.createElement('script');
  var prior = document.getElementsByTagName('script')[0];
  script.async = 1;

  script.onload = script.onreadystatechange = (_, isAbort) => {
    if (
      isAbort ||
      !script.readyState ||
      /loaded|complete/.test(script.readyState)
    ) {
      script.onload = script.onreadystatechange = null;
      script = undefined;
      if (!isAbort) if (callback) callback();
    }
  };

  script.src = source;
  prior.parentNode.insertBefore(script, prior);
};

/**
 * Remove certain query params from a url
 * Example: <a href="#" onclick="removeURLParam('foo');return false;">URL without FOO</a>
 * This is used to remove certain Ransack filter from URLs
 */

 window.removeURLParam = (param) => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  // loop trough all url params
  for (const k of params.keys()) {
    // if the key _contains_ the value given to this function
    if (k.includes(param)) {
      // remove it
      params.delete(k);
    }
  }

  // visit the new url with Turbo
  Turbolinks.visit(`?${params.toString()}`);
};

/**
 * Monkey patch Turbolinks to render 403, 404 & 500 normally
 * See https://github.com/turbolinks/turbolinks/issues/179#issuecomment-289287888
 * See https://remarkgroup.atlassian.net/browse/VPD-1853
 */

 window.Turbolinks.HttpRequest.prototype.requestLoaded = function() {
  return this.endRequest(function() {
    var code = this.xhr.status;
    if (200 <= code && code < 300 ||
        code === 403 || code === 404 || code === 500) {
      this.delegate.requestCompletedWithResponse(
          this.xhr.responseText,
          this.xhr.getResponseHeader("Turbolinks-Location"));
    } else {
      this.failed = true;
      this.delegate.requestFailedWithStatusCode(code, this.xhr.responseText);
    }
  }.bind(this));
};
