import React from 'react';
import PropTypes from 'prop-types';
import { Element, Detail, Content } from '@remark/components';
import styles from './email.module.css';

const Email = ({ value, fields, editMode }) => <React.Fragment>
  {!editMode && fields && fields.length > 0 && fields.map((field, index) => 
    <React.Fragment key={index}>
      <Detail className={styles._detail} label={{ text: field.label }} />
      <div className={styles._inputblock}>
        {(field.type === 'text-editor') && <Element
          className={styles._readonly}
          initialContent={field.type === 'text-editor' && (field.value || '–')}
          type='text-editor'
          toolbarHidden
          readOnly
        />}
        {(field.type === 'text') && <Content text={field.value || '–'} />}
      </div>
    </React.Fragment>
  )}
  {editMode &&
    fields &&
    fields.length > 0 &&
    fields.map((field, index) => (
      <div key={index} className={styles._inputblock}>
        <Element
          className={[field.className, styles._field].join(' ')}
          initialContent={field.type === 'text-editor' && field.value}
          maxHeight={field.type === 'text-editor'}
          {...field}
        />
      </div>
    ))}
</React.Fragment>;

Email.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({})),
  fields: PropTypes.arrayOf(PropTypes.shape(Element.propTypes)),
  editMode: PropTypes.bool,
};

Email.defaultProps = {
  value: null,
  fields: null,
  editMode: false,
};

// Needed for Storybook
Email.displayName = 'Email';

export default Email;
