import React from 'react';
import Menu from '.';

export default {
  title: 'Components/Menu',
  component: Menu,
};

const Template = (args) => <Menu {...args} />;

export const Default = Template.bind({});
Default.args = {
  title: {
    text: 'Company',
    color: 'dark',
  },
  description: {
    size: 'label',
    color: 'stable-900',
    text: 'Generic company settings',
  },
  descriptionIcon: 'icon-info',
  actions: [
    {
      text: 'Company name',
      color: 'stable-100',
      action: '#',
    },
    {
      text: 'Company address',
      color: 'stable-100',
      action: '#',
    },
    {
      text: 'Company website',
      color: 'stable-100',
      action: '#',
    },
    {
      text: 'Website footer',
      color: 'stable-100',
    },
  ],
};

export const withBreadcrumbs = () => {
  const args = {
    ...Default.args,
    title: {
      text: 'Invitation',
      color: 'dark',
    },
    description: {
      text: 'Invitation mail to invite an applicant to fill the online medical questionnaire.',
    },
    actions: [
      {
        text: 'Trigger and delay',
        color: 'stable-100',
      },
      {
        text: 'Recipient',
        color: 'stable-100',
      },
      {
        text: 'Subject and content',
        color: 'stable-100',
      },
    ],
    breadcrumbs: {
      items: [
        {
          action: '#',
          text: 'Electronic underwriting',
        },
      ],
    },
  };
  return <Menu {...args} />;
};
