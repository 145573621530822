import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Element,
  Content,
  Button,
  Container,
  Flex,
  Cell,
  Divider,
  Icon,
  Tooltip,
} from '@remark/components';
import TextEditor from '../setting/_partials/textEditor';
import Attachment from '../setting/_partials/attachment';
import Repeater from '../setting/_partials/repeater';
import Duration from '../setting/_partials/duration';
import Email from '../setting/_partials/email';
import Text from '../setting/_partials/text';
import styles from './setting_row.module.css';

const SettingRow = ({
  property,
  value,
  meta,
  fields,
  type,
  action,
  draggable,
  disabledSetting,
  header,
  password,
  timeUnits,
  timeOptions,
  hideEdit,
}) => {
  const [editMode, setEditMode] = useState(
    fields
      ? fields.filter((field) => field?.errors?.length > 0).length > 0
      : false
  );

  // if content is array, show multiple <Content />'s
  const handleContent = (content) => {
    if (Array.isArray(content)) {
      return content.map((item, i) => (
        <Content key={i} className={styles.content} {...item} />
      ));
    }
    return <Content className={styles.content} inline {...content} />;
  };

  const renderEditButton = (translation) => (
    <Button
      className={styles._action}
      text={translation}
      action={(e) => {
        if (window.activeEditForms > 0) {
          window.showUnsavedModal(window.location.href);
        } else {
          setEditMode(true);
          window.activeEditForms++;
        }
        e.preventDefault();
      }}
      link
      color={'brave-500'}
      size={'no-p'}
      disabled={disabledSetting || editMode}
      underline
      type={'button'}
      {...action}
    />
  );

  return (
    <div data-turbolinks-persist-scroll>
      <Container
        full
        className={[
          styles._wrapper,
          editMode && styles._wrapperEdit,
          header && styles.header,
        ].join(' ')}
      >
        <Flex start between wrap gutter={false}>
          {property && (
            <Cell
              width={{ s: property?.cells?.s || 6, m: property?.cells?.m || 2 }}
            >
              {draggable && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon name='drag' width='18' height='14' color='stable-700' />
                  <Divider width={10} />
                  {handleContent(property?.content)}
                </div>
              )}
              {!draggable &&
                property?.content &&
                handleContent(property?.content)}
            </Cell>
          )}
          {value && (
            <Cell width={{ s: value?.cells?.s || 6, m: value?.cells?.m || 5 }}>
              {value?.content
                ? password
                  ? handleContent({ text: '•••••••' })
                  : handleContent(value.content)
                : !hideEdit && renderEditButton(i18n.t('add_setting'))}
            </Cell>
          )}
          <Cell width={{ s: meta?.cells?.s || 12, m: meta?.cells?.m || 5 }}>
            <Flex middle between wrap gutter={false}>
              <div className={styles.metaWrapper}>
                {meta?.icon && (
                  <Tooltip
                    tippyOptions={{ theme: 'dark' }}
                    content={{ text: `${i18n.t('not_pushed_content')}` }}
                    trigger={'mouseenter'}
                  >
                    <Icon
                      name={'icon-alert'}
                      color={'energized-500'}
                      width={18}
                      height={18}
                    />
                  </Tooltip>
                )}
                {meta?.icon && meta?.content && <Divider width={5} />}
                {meta?.content && handleContent(meta.content)}
              </div>
              {(action || fields?.length > 0) &&
                !editMode &&
                !draggable &&
                !hideEdit &&
                renderEditButton(i18n.t('edit'))}
            </Flex>
          </Cell>
        </Flex>
        {editMode && (
          <>
            <Divider
              height={1}
              margin={[10, 0, 15, 0]}
              border={{ dashed: true }}
              color={'stable-500'}
            />
            <Flex middle end gutter={false}>
              <Cell
                width={{
                  s: property?.cells?.s || 12,
                  m: value?.cells?.m + meta?.cells?.m || 10,
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className={!(type === 'duration') ? styles.grow : ''}>
                    {type === 'attachment' ||
                    type === 'jpg_png_attachment' ||
                    type === 'yml_attachment' ? (
                      <Attachment
                        title={title}
                        value={value}
                        fields={fields}
                        type={type}
                        editMode={editMode}
                        setEditMode={() => setEditMode(!editMode)}
                      />
                    ) : type === 'checkbox_array' ? (
                      <Repeater
                        value={value}
                        fields={fields}
                        type={type}
                        editMode={editMode}
                      />
                    ) : type === 'rich_text' ? (
                      <TextEditor
                        value={value}
                        fields={fields}
                        type={type}
                        editMode={editMode}
                      />
                    ) : type === 'email' ? (
                      <Email
                        value={value}
                        fields={fields}
                        type={type}
                        editMode={editMode}
                      />
                    ) : type === 'duration' ? (
                      <Duration
                        value={value}
                        fields={fields}
                        type={type}
                        editMode={editMode}
                        timeUnits={timeUnits}
                        timeOptions={timeOptions}
                      />
                    ) : (
                      <Text
                        value={value}
                        fields={fields}
                        type={type}
                        editMode={editMode}
                      />
                    )}
                  </div>
                  <Divider width={10} />
                  <Button
                    className={
                      (styles.action,
                      type === 'duration' && styles.action_duration)
                    }
                    text={i18n.t('cancel')}
                    action={(e) => {
                      window.activeEditForms--;
                      setEditMode(false);
                      e.preventDefault();
                    }}
                    outline
                    color={'brave-500'}
                    type={'button'}
                  />
                  <Divider width={10} />
                  <Button
                    className={
                      (styles.action,
                      type === 'duration' && styles.action_duration)
                    }
                    text={i18n.t('save')}
                    action={() => window.activeEditForms--}
                    outline
                    color={'brave-500'}
                    type={'submit'}
                  />
                </div>
              </Cell>
            </Flex>
          </>
        )}
      </Container>
    </div>
  );
};
SettingRow.propTypes = {
  /* Content & size of first cell */
  property: PropTypes.shape({
    cells: PropTypes.shape({
      s: PropTypes.number,
      m: PropTypes.number,
      l: PropTypes.number,
      xl: PropTypes.number,
      xxl: PropTypes.number,
    }),
    content: PropTypes.oneOfType([
      PropTypes.shape(Content.propTypes),
      PropTypes.array,
    ]),
  }),
  /* Disables edit */
  draggable: PropTypes.bool,
  /* Content & size of second cell */
  value: PropTypes.shape({
    cells: PropTypes.shape({
      s: PropTypes.number,
      m: PropTypes.number,
      l: PropTypes.number,
      xl: PropTypes.number,
      xxl: PropTypes.number,
    }),
    content: PropTypes.oneOfType([
      PropTypes.shape(Content.propTypes),
      PropTypes.array,
    ]),
  }),
  /* Content & size of third cell */
  meta: PropTypes.shape({
    cells: PropTypes.shape({
      s: PropTypes.number,
      m: PropTypes.number,
      l: PropTypes.number,
      xl: PropTypes.number,
      xxl: PropTypes.number,
    }),
    content: PropTypes.oneOfType([
      PropTypes.shape(Content.propTypes),
      PropTypes.array,
    ]),
  }),
  /* Editable fields below data */
  fields: PropTypes.arrayOf(PropTypes.shape(Element.propTypes)),
  /* Type of rendered fields */
  type: PropTypes.string,
  /* Override default action */
  action: PropTypes.shape(Button.propTypes),
  disabledSetting: PropTypes.bool,
  header: PropTypes.bool,
  password: PropTypes.bool,
  hideEdit: PropTypes.bool,
};

SettingRow.defaultProps = {
  property: null,
  value: null,
  meta: null,
  fields: null,
  type: null,
  action: null,
  draggable: false,
  disabledSetting: false,
  header: false,
  password: false,
  hideEdit: false,
};

// Needed for Storybook
SettingRow.displayName = 'SettingRow';

export default SettingRow;
