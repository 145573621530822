import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Element, Content, Divider } from '@remark/components';
import styles from './duration.module.css';

const Duration = ({ value, fields, editMode, preText, timeUnits, timeOptions}) => {
  const [values, setValues] = useState(fields.map((field) => field.value));
  const [mode, setMode] = useState(() => {
    const initialValues = fields.map((field) => field.value);
    switch(JSON.stringify(initialValues)) {
      case '[0,0,0]':
        return 'immediately';
      case '[null,null,null]':
        return 'never';
      default:
        return 'manual';
    }
  });

  const time = (val, labels) => {
    let result = [];

    val.text[0] && result.push(`${val.text[0]} ${labels.days}`);
    val.text[1] && result.push(`${val.text[1]} ${labels.hours}`);
    val.text[2] && result.push(`${val.text[2]} ${labels.minutes}`);
    result.length && result.unshift('+');
    result.length > 2 && result.splice(result.length - 1, 0, labels.and);

    return result.join(' ');
  }

  return(
  <div className={!editMode ? styles._row : styles._column}>
    {preText && <>
      <Content text={preText} />
      <Divider height={15} />
    </>}

    {editMode &&
      <Element
        type={'radio'}
        onChange={(e) => {
          setMode(e.target.value);
          e.target.value == 'immediately' && setValues(['0', '0', '0']);
          e.target.value == 'never' && setValues(['', '', '']);
        }}
        name={'when'}
        options={timeOptions.map(option => ({
          checked: mode === option.value,
          label: option.text,
          value: option.value,
        }))}
      />
    }
    <div className={styles._wrapper}>
      {!editMode && value && (
        <>
          <Divider width={10} />
          <Content text={time(value, timeUnits)} />
        </>
      )}
      {editMode &&
        fields &&
        fields.length > 0 &&
        fields.map((field, index) => {

          // only show the error on the last field
          if (index !== 2) {
            delete field.errors;
          }

          field.value = field.value == null ? '0' : field.value.toString(10)
          return(
            <div key={index} className={styles._innerwrapper}>
              <div className={styles._inputblock}>
                <Element
                  className={field.className}
                  disabled={mode != 'manual'}
                  {...field}
                />
                {(['immediately', 'never'].includes(mode)) &&
                  <Element name={field.name} type="hidden" value={values[index]} />
                }
                <Content
                  className={styles._sublabel}
                  color={'stable-900'}
                  size={'label'}
                  text={
                    index === 0
                      ? timeUnits.days
                        ? timeUnits.days
                        : 'day(s)'
                      : index === 1
                      ? timeUnits.hours
                        ? timeUnits.hours
                        : 'hour(s)'
                      : timeUnits.minutes
                      ? timeUnits.minutes
                      : 'minute(s)'
                  }
                />
              </div>
              {index === 1 && (
                <Content
                  text={timeUnits.maxHours ? timeUnits.maxHours : 'Max 23 hours'}
                  size={'sublabel'}
                  color={'stable-900'}
                  className={styles._label}
                />
              )}
              {index === 2 && (
                <Content
                  text={
                    timeUnits.maxMinutes ? timeUnits.maxMinutes : 'Max 59 minutes'
                  }
                  size={'sublabel'}
                  color={'stable-900'}
                  className={styles._label}
                />
              )}
            </div>
          )
        })}
    </div>
  </div>
)};


Duration.propTypes = {
  value: PropTypes.shape(Content.propTypes),
  fields: PropTypes.arrayOf(PropTypes.shape(Element.propTypes)),
  editMode: PropTypes.bool,
  preText: PropTypes.string,
  timeUnits: PropTypes.objectOf(PropTypes.string),
};

Duration.defaultProps = {
  value: null,
  fields: null,
  editMode: false,
  preText: '',
  timeUnits: {},
  timeOptions: ['manual', 'immediately', 'never']
};

// Needed for Storybook
Duration.displayName = 'Duration';

export default Duration;
