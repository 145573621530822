import React from 'react';
import Section from '.';

export default {
  title: 'Components/Section',
  component: Section,
};

const Template = (args) => <Section {...args} />;

export const Default = Template.bind({});

Default.args = {
  title: {
    text: 'Applicant creation and management',
  },
  description: {
    text: 'Applicant creation and management description',
  },
  action: {
    text: 'Action',
    icon: { name: 'plus-round' },
  },
};
