import React from 'react';
import { Divider } from '@remark/components';
import SettingRow from '.';

export default {
  title: 'Components/SettingRow',
  component: SettingRow,
};

const Template = (args) => <SettingRow {...args} />;

export const Default = Template.bind({});
Default.args = {
  property: {
    content: {
      text: 'Company name',
    },
  },
  value: {
    content: {
      text: 'Acme assurances',
    },
  },
  meta: {
    content: {
      text: 'Last udated 2 March 2021 by Simon Visor',
    },
  },
  fields: [
    {
      name: 'rails_name',
      value: 'Acme assurances',
      type: 'text',
    },
  ],
  type: 'text',
};

export const AsHead = (args) => <SettingRow {...args} />;

AsHead.args = {
  property: {
    content: {
      size: 'label',
      color: 'stable-900',
      text: 'Property',
    },
  },
  value: {
    content: {
      size: 'label',
      color: 'stable-900',
      text: 'Value',
    },
  },
  meta: {
    content: {
      size: 'label',
      color: 'stable-900',
      text: 'Last modified',
    },
  },
};

export const Combined = () => (
  <>
    <Divider height={1} color={'stable-500'} />
    <SettingRow {...AsHead.args} />
    <Divider height={1} color={'stable-500'} />
    <SettingRow {...Default.args} />
    <Divider height={1} color={'stable-500'} />
    <SettingRow {...Default.args} />
    <Divider height={1} color={'stable-500'} />
    <Divider height={1} color={'stable-500'} />
    <Divider height={15} color={'stable-300'} />
    <Divider height={1} color={'stable-500'} />
    <SettingRow {...AsHead.args} />
    <Divider height={1} color={'stable-500'} />
    <SettingRow {...Default.args} />
    <Divider height={1} color={'stable-500'} />
    <SettingRow {...Default.args} />
    <Divider height={1} color={'stable-500'} />
  </>
);

export const WithError = (args) => <SettingRow {...args} />;

WithError.args = {
  property: {
    content: {
      text: 'Company name',
    },
  },
  value: {
    content: {
      text: 'Acme assurances',
    },
  },
  meta: {
    content: {
      text: 'Last udated 2 March 2021 by Simon Visor',
    },
  },
  fields: [
    {
      name: 'rails_name',
      value: 'Acme assurances',
      type: 'text',
      errors: ['Something went wrong'],
    },
  ],
  type: 'text',
};

export const WithCustomAction = (args) => <SettingRow {...args} />;

WithCustomAction.args = {
  property: {
    content: {
      text: 'Company name',
    },
  },
  value: {
    content: {
      text: 'Acme assurances',
    },
  },
  meta: {
    content: {
      text: 'Last udated 2 March 2021 by Simon Visor',
    },
  },
  action: {
    action: 'https://google.com',
    customData: {
      'data-remote': 'true',
    },
  },
};

export const Duration = (args) => (
  <>
    <SettingRow {...args} />
  </>
);

Duration.args = {
  property: {
    content: {
      text: 'Timestamp',
    },
    cells: {
      s: 6,
      m: 3,
    },
  },
  value: {
    content: {
      text: '0 days 12 hours 56 minutes',
    },
    cells: {
      s: 6,
      m: 4,
    },
  },
  meta: {
    content: {
      text: 'Last udated 2 March 2021 by Simon Visor',
    },
    cells: {
      s: 12,
      m: 5,
    },
  },
  fields: [
    {
      name: 'rails_name',
      value: '00',
      type: 'number',
    },
    {
      name: 'rails_name',
      value: '12',
      type: 'number',
    },
    {
      name: 'rails_name',
      value: '56',
      type: 'number',
    },
  ],
  type: 'duration',
};
