import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Element, Content, Flex, Divider } from '@remark/components';
// import Value from '../value';
import styles from './text.module.css';

const Text = ({ value, fields, editMode, column }) => (
  <Flex gutter={false} vertical={column}>
    {!editMode && value && <Content {...value} />}
    {editMode &&
      fields &&
      fields.length > 0 &&
      fields.map((field, index) => (
        <div className={styles._inputblock} key={index}>
          <Element
            className={[field.className, styles._field].join(' ')}
            initialContent={field.type === 'text-editor' && value.text[index]}
            {...field}
          />
        </div>
      ))}
  </Flex>
);

Text.propTypes = {
  value: PropTypes.shape(Content.propTypes),
  fields: PropTypes.arrayOf(PropTypes.shape(Element.propTypes)),
  editMode: PropTypes.bool,
  column: PropTypes.bool,
};

Text.defaultProps = {
  value: null,
  fields: null,
  editMode: false,
  column: false,
};

// Needed for Storybook
Text.displayName = 'Text';

export default Text;
