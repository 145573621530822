import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Element,
  Title,
  Content,
  MixedContent,
  Button,
  Container,
  Flex,
  Cell,
  Divider,
  Modal,
  Badge,
  Icon,
  Tooltip,
} from '@remark/components';

import TextEditor from './_partials/textEditor';
import Attachment from './_partials/attachment';
import Repeater from './_partials/repeater';
import Duration from './_partials/duration';
import Email from './_partials/email';
import Text from './_partials/text';

import styles from './setting.module.css';

const Setting = ({
  title,
  description,
  value,
  preText,
  timeUnits,
  timeOptions,
  fields,
  type,
  exampleValue,
  exampleDescription,
  action,
  supportedFiles,
  disabledSetting,
  id,
  label,
  pushed,
  empty,
  max,
}) => {
  const [editMode, setEditMode] = useState(
    fields
      ? fields.filter((field) => field?.errors?.length > 0).length > 0
      : false
  );

  // check if one of the values of checkboxes has an empty value
  const hasEmptyValueInCheckboxes =
    type == 'checkbox_array' &&
    fields.filter((field) => field?.value == '').length > 0;

  return (
    <div data-turbolinks-persist-scroll>
      <Container full className={styles._self} id={id}>
        {(title || description) && (
          <Flex between wrap gutter={false}>
            <Cell width={{ m: 8 }}>
              <Flex gutter={false} middle>
                {title && <Title {...title} />}
                {label && (
                  <div className={styles.label}>
                    <Badge
                      size='sublabel'
                      color='stable-700'
                      className={styles.badge}
                      content={{
                        // convert 'en' to 'EN', but not 'All languages'
                        text: label.length == 2 ? label.toUpperCase() : label,
                        size: 'sublabel',
                        color: 'stable-900',
                      }}
                    />
                  </div>
                )}
              </Flex>
              {empty && <Content {...description} />}
              {!pushed && description && !empty && (
                <Content
                  {...description}
                  text={`${i18n.t('settings.last_modified_colon')} ${
                    description.text
                  } `}
                />
              )}
              {pushed && description && (
                <div className={styles.pushedWrapper}>
                  <Tooltip
                    tippyOptions={{ theme: 'dark' }}
                    content={{ text: `${i18n.t('not_pushed_content')}` }}
                    trigger={'mouseenter'}
                  >
                    <Icon
                      name={'icon-alert'}
                      color={'energized-500'}
                      width={18}
                      height={18}
                    />
                  </Tooltip>
                  <Divider width={5} />
                  {description && <Content {...description} />}
                </div>
              )}
              {((!editMode && value?.text) || editMode) && (
                <Divider height={15} />
              )}
            </Cell>
            <Cell width={{ m: 4 }}>
              <Flex end gutter={false}>
                {(exampleValue || exampleDescription) && (
                  <Modal
                    title={{ text: `${title.text} (example)`, size: 'h5' }}
                    dismiss={{
                      icon: {
                        name: 'cross-alt',
                        width: 20,
                        height: 20,
                      },
                    }}
                    trigger={{
                      className: styles._action,
                      color: 'brave-500',
                      size: 's',
                      icon: { name: 'question-circle', width: 25, height: 25 },
                      type: 'button',
                    }}
                    removePortalNode={false}
                  >
                    {exampleDescription && (
                      <Content
                        className={styles._exampleDescription}
                        text={exampleDescription}
                        color={'stable-900'}
                      />
                    )}
                    {exampleValue && (
                      <Content
                        className={styles._exampleSubheader}
                        text={i18n.t('example')}
                        color={'stable-900'}
                        size={'label'}
                      />
                    )}
                    {exampleValue && (
                      <MixedContent
                        className={styles._exampleValue}
                        text={exampleValue}
                      />
                    )}
                  </Modal>
                )}
                <Button
                  className={styles._action}
                  icon={{
                    name: 'edit',
                    width: 15,
                    height: 15,
                  }}
                  text={i18n.t('edit')}
                  action={(e) => {
                    if (window.activeEditForms > 0) {
                      window.showUnsavedModal(window.location.href);
                    } else {
                      setEditMode(true);
                      window.activeEditForms++;
                    }
                    e.preventDefault();
                  }}
                  outline
                  color={'brave-500'}
                  size={'s'}
                  disabled={disabledSetting || editMode}
                  type={'button'}
                  {...action}
                />
              </Flex>
            </Cell>
            <Cell width={{ m: 12 }}>
              {value.text &&
                !value.text[0] &&
                type !== 'attachment' &&
                type !== 'jpg_png_attachment' &&
                type !== 'yml_attachment' &&
                type !== 'duration' &&
                !hasEmptyValueInCheckboxes &&
                !editMode && (
                  <Button
                    text={i18n.t('add_setting')}
                    link
                    color={'brave-500'}
                    underline
                    type={'button'}
                    action={(e) => {
                      if (window.activeEditForms > 0) {
                        window.showUnsavedModal(window.location.href);
                      } else {
                        setEditMode(true);
                        window.activeEditForms++;
                      }
                      e.preventDefault();
                    }}
                    {...action}
                  />
                )}
              {((!editMode && (value?.text || value.length > 0)) ||
                editMode) && (
                <Flex gutter={false} className={styles._inputWrapper}>
                  <Cell>
                    {type === 'attachment' ||
                    type === 'jpg_png_attachment' ||
                    type === 'yml_attachment' ? (
                      <Attachment
                        title={title}
                        value={value}
                        fields={fields}
                        type={type}
                        editMode={editMode}
                        allowedFileTypes={
                          type === 'jpg_png_attachment'
                            ? ['image/jpg', 'image/jpeg', 'image/png']
                            : type === 'yml_attachment'
                            ? ['application/x-yaml']
                            : false
                        }
                        setEditMode={() => setEditMode(!editMode)}
                        supportedFiles={supportedFiles}
                      />
                    ) : type === 'checkbox_array' ? (
                      <Repeater
                        value={value}
                        fields={fields}
                        type={type}
                        editMode={editMode}
                      />
                    ) : type === 'select_array' ? (
                      <Repeater
                        value={value}
                        fields={fields}
                        type={type}
                        editMode={editMode}
                      />
                    ) : type === 'rich_text' ? (
                      <TextEditor
                        value={value}
                        fields={fields}
                        type={type}
                        editMode={editMode}
                      />
                    ) : type === 'repeater' ? (
                      <Repeater
                        value={value}
                        fields={fields}
                        type={type}
                        editMode={editMode}
                        max={max}
                      />
                    ) : type === 'email' ? (
                      <Email
                        value={value}
                        fields={fields}
                        type={type}
                        editMode={editMode}
                      />
                    ) : type === 'duration' ? (
                      <Duration
                        value={value}
                        preText={preText}
                        timeUnits={timeUnits}
                        timeOptions={timeOptions}
                        fields={fields}
                        type={type}
                        editMode={editMode}
                      />
                    ) : (
                      <Text
                        value={value}
                        fields={fields}
                        type={type}
                        editMode={editMode}
                      />
                    )}
                    {editMode && <Divider height={11} />}
                    {editMode && (
                      <Flex end gutter={false}>
                        <Button
                          text={i18n.t('cancel')}
                          action={() => {
                            window.activeEditForms--;
                            setEditMode(false);
                          }}
                          link
                          color={'brave-500'}
                          underline
                          type={'button'}
                        />
                        <Button
                          className={styles._action}
                          text={i18n.t('save')}
                          link
                          color={'brave-500'}
                          underline
                          type={'submit'}
                          action={() => window.activeEditForms--}
                        />
                      </Flex>
                    )}
                  </Cell>
                </Flex>
              )}
            </Cell>
          </Flex>
        )}
      </Container>
    </div>
  );
};
Setting.propTypes = {
  title: PropTypes.shape(Title.propTypes),
  description: PropTypes.shape(Content.propTypes),
  value: PropTypes.oneOfType([
    PropTypes.shape(Content.propTypes),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  fields: PropTypes.arrayOf(PropTypes.shape(Element.propTypes)),
  type: PropTypes.string,
  action: PropTypes.shape(Button.propTypes),
  supportedFiles: PropTypes.string,
  disabledSetting: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  pushed: PropTypes.bool,
  empty: PropTypes.bool,
  max: PropTypes.number,
};

Setting.defaultProps = {
  title: null,
  description: null,
  value: null,
  fields: null,
  type: '',
  supportedFiles: null,
  disabledSetting: false,
  id: null,
  label: null,
  pushed: false,
  empty: false,
  max: null,
};

// Needed for Storybook
Setting.displayName = 'Setting';

export default Setting;
