import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Element,
  Content,
  MixedContent,
  Button,
  Wrapper,
  Flex,
  Divider,
} from '@remark/components';
import styles from './repeater.module.css';

const Repeater = ({ value, fields, editMode, max }) => {
  const [innerFields, setInnerFields] = useState(fields);

  const addField = (index, field) => {
    const mutatedFields = [...innerFields];

    mutatedFields.push({
      errors: [],
      name: `setting_edit[values][${index + 1}]`,
      type: field.type,
      options: field.type === 'select' && fields[0].options, // set options when type is select
      value: field.type !== 'select' && '', // otherwise set value,
      repeatFields: field.repeatFields && [...field.repeatFields],
    });
    setInnerFields(mutatedFields);
  };

  const removeField = (index) => {
    const mutatedFields = [...innerFields];
    mutatedFields.splice(index, 1);
    setInnerFields(mutatedFields);
  };

  return (
    <div>
      {!editMode &&
        Array.isArray(value.text) &&
        value.text
          .filter((text) => text)
          .map((item, index) => (
            <Wrapper className={styles._block} key={index}>
              <Flex wrap={false} gutter={false}>
                {fields[0].type !== 'select' && (
                  <Element type={'checkbox'} disabled />
                )}
                <MixedContent text={item} />
              </Flex>
            </Wrapper>
          ))}
      {editMode &&
        innerFields &&
        innerFields.length > 0 &&
        innerFields.map((field, index) => (
          <div className={styles._inputblock} key={index}>
            <div className={styles._fieldWrapper}>
              {/* EXTRA: Specific extra fields that are only displayed in the first row of the fields  */}
              {field?.extraFields?.map((item, index) => (
                <React.Fragment key={index}>
                  <Divider height={10} color={'transparent'} />
                  <Element
                    className={[field.className, styles._field].join(' ')}
                    initialContent={item.type === 'text-editor' && item?.value}
                    name={`extraField_${index}`}
                    {...item}
                  />
                </React.Fragment>
              ))}
              {/* Multiple fields in a repeat */}
              {field?.repeatFields?.map((item, index) => (
                <React.Fragment key={index}>
                  <Divider height={10} color={'transparent'} />
                  <Element
                    className={[field.className, styles._field].join(' ')}
                    initialContent={item.type === 'text-editor' && item?.value}
                    name={`repeatField_${index}`}
                    {...item}
                  />
                </React.Fragment>
              ))}
              {/* Single field in a repeat */}
              {!field?.repeatFields && (
                <Element
                  className={[field.className, styles._field].join(' ')}
                  initialContent={
                    field.type === 'text-editor' && value.text[index]
                  }
                  {...field}
                />
              )}
            </div>
            {/* Custom functionallity to add/remove fields */}
            <div className={styles._modification}>
              <Button
                className={styles._action}
                icon={{
                  name: 'icon-trash',
                  color: 'assertive-500',
                  width: 20,
                  height: 20,
                }}
                action={(e) => {
                  e.preventDefault();
                  removeField(index);
                }}
                outline
                color={'brave-500'}
                size={'s'}
                disabled={innerFields.length === 1}
              />
              {innerFields.length === index + 1 && (
                <>
                  <Divider height={10} />
                  <Button
                    className={styles._action}
                    icon={{
                      name: 'icon-plus',
                      width: 11,
                      height: 11,
                    }}
                    action={(e) => {
                      e.preventDefault();
                      addField(index, field);
                    }}
                    outline
                    color={'brave-500'}
                    size={'s'}
                    disabled={innerFields.length === max}
                  />
                </>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

Repeater.propTypes = {
  value: PropTypes.shape(Content.propTypes),
  fields: PropTypes.arrayOf(PropTypes.shape(Element.propTypes)),
  editMode: PropTypes.bool,
  max: PropTypes.number,
};

Repeater.defaultProps = {
  value: null,
  fields: null,
  editMode: false,
  max: null,
};

// Needed for Storybook
Repeater.displayName = 'Repeater';

export default Repeater;
