import React from 'react';
import PropTypes from 'prop-types';
import {
  Element,
  Title,
  Content,
  Button,
  Divider,
  Wrapper,
  IconCard,
} from '@remark/components';
import styles from './attachment.module.css';

const Attachment = ({
  title,
  value,
  fields,
  editMode,
  setEditMode,
  supportedFiles,
  allowedFileTypes,
}) => {
  const incommingFile = value.text[0]?.filename
    ? {
        name: `${value.text[0]?.filename}`,
        type: value?.text[0]?.content_type,
        size: value?.text[0]?.byte_size,
      }
    : null;

  const bytesToSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
  };

  const additionalProps = {
    content: {
      icon: {
        name: 'file-types',
      },
      uploadedDocumentsText: {
        text: i18n.t('upload.uploaded_documents'),
      },
      title: {
        text: i18n.t('upload.drag_and_drop'),
      },
      pre: {
        text: i18n.t('or'),
        size: 'label',
      },
      content: {
        text: i18n.t('upload.browse_files'),
        size: 'label',
        color: 'brave-500',
      },
      description: null,
      mobile: {
        title: {
          text: i18n.t('upload.pick_your_document'),
        },
        pre: {
          text: '- ',
          size: 'label',
        },
        content: { text: i18n.t('upload.browse_files'), size: 'label' },
      },
    },
    iconPdf: {
      name: 'file-pdf',
    },
    iconJpg: {
      name: 'file-jpg',
    },
    iconPng: {
      name: 'file-png',
    },
    iconYml: {
      name: 'file-yml',
    },
    type: 'file',
    incommingFile,
  };
  return (
    <div>
      {!editMode && value.text[0] !== null ? (
        <div className={styles._fold}>
          <IconCard
            title={{
              text: value?.text[0]?.filename,
              size: 'h6',
            }}
            icon={{
              name:
                value?.text[0]?.content_type === 'image/jpeg'
                  ? 'file-jpg'
                  : value?.text[0]?.content_type === 'image/png'
                  ? 'file-png'
                  : value?.text[0]?.content_type === 'application/x-yaml'
                  ? 'file-yml'
                  : 'file-pdf',
              width: 30,
            }}
            content={{
              text: `${value?.text[0]?.content_type} ${bytesToSize(
                value?.text[0]?.byte_size
              )}`,
              size: 'label',
            }}
            action={value.action}
            target={'blank'}
          />
        </div>
      ) : (
        !editMode &&
        value.text[0] === null && (
          <Wrapper className={styles._valuewrapper} size={'l'} shadow={false}>
            <Title
              className={styles._valuewrapperTitle}
              text={`${i18n.t('no')} ${title.text.toLowerCase()} ${i18n.t(
                'added'
              )}`}
              size={'h5'}
            />
            <Divider height={5} color={'transparent'} />
            <div className={styles._innerwrapper}>
              <Content
                text={`${i18n.t('please_add_a')} ${title.text.toLowerCase()}`}
                color={'stable-900'}
                size={'body'}
              />
              <Divider width={5} color={'transparent'} />
              <Button
                className={styles._valuewrapperButton}
                link
                action={(e) => {
                  if (window.activeEditForms > 0) {
                    window.showUnsavedModal(window.location.href);
                  } else {
                    setEditMode(true);
                    window.activeEditForms++;
                  }
                  e.preventDefault();
                }}
                text={i18n.t('editing')}
                color={'brave-500'}
                underline
              />
              <Divider width={5} color={'transparent'} />
              <Content
                text={i18n.t('this_field')}
                size={'body'}
                color={'stable-900'}
              />
            </div>
          </Wrapper>
        )
      )}

      {editMode &&
        fields &&
        fields.length > 0 &&
        fields.map((field, index) => (
          <div className={styles._inputblock} key={index}>
            <Element
              className={[field.className, styles._field].join(' ')}
              initialContent={field.type === 'text-editor' && value.text[index]}
              allowedFileTypes={allowedFileTypes}
              {...field}
              {...additionalProps}
            />
            <Content
              text={supportedFiles}
              size={'sublabel'}
              color={'stable-900'}
            />
          </div>
        ))}
    </div>
  );
};
Attachment.propTypes = {
  title: PropTypes.shape(Title.propTypes),
  description: PropTypes.shape(Content.propTypes),
  value: PropTypes.shape(Content.propTypes),
  fields: PropTypes.arrayOf(PropTypes.shape(Element.propTypes)),
  editMode: PropTypes.bool,
  setEditMode: PropTypes.func,
  supportedFiles: PropTypes.string,
  allowedFileTypes: PropTypes.array,
};

Attachment.defaultProps = {
  title: null,
  description: null,
  value: null,
  fields: null,
  editMode: false,
  setEditMode: null,
  supportedFiles: null,
  allowedFileTypes: false,
};

// Needed for Storybook
Attachment.displayName = 'Attachment';

export default Attachment;
