import React from 'react';
import Top from '.';

export default {
  title: 'Components/Top',
  component: Top,
};

const Template = (args) => 
    <div style={{height: '2000px'}}>
        Scroll down to test!
        <Top {...args} />
    </div>;

export const Default = Template.bind({});
Default.args = {
  text: 'Scroll to top'
};
