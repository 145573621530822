import React from 'react';
import Tags from '.';

export default {
  title: 'Components/Tags',
  component: Tags,
};

const Template = (args) => <Tags {...args} />;

export const Default = Template.bind({});

Default.args = {
  title: {
    text: 'Dynamic text',
  },
  description: {
    text: 'Smart text string to populate fields',
  },
  search: {
    placeholder: 'Search...',
  },
  details: [
    {
      label: {
        text: 'Custom number',
      },
      value: {
        clipboard: {
          button: {
            text: '{{nrc}}',
            icon: { name: 'icon-copy', height: 14, width: 14 },
          },
          badge: {
            content: {
              text: 'Copied!',
              color: 'dark',
            },
          },
        },
      },
    },
    {
      label: {
        text: 'Salutation based on gender (Mr., Ms.)',
      },
      value: {
        clipboard: {
          button: {
            text: '{{salutation}}',
            icon: { name: 'icon-copy', height: 14, width: 14 },
          },
          badge: {
            content: {
              text: 'Copied!',
              color: 'dark',
            },
          },
        },
      },
    },
    {
      label: {
        text: 'First name of applicant',
      },
      value: {
        clipboard: {
          button: {
            text: '{{firstname_applicant}}',
            icon: { name: 'icon-copy', height: 14, width: 14 },
          },
          badge: {
            content: {
              text: 'Copied!',
              color: 'dark',
            },
          },
        },
      },
    },
    {
      label: {
        text: 'Family name of applicant',
      },
      value: {
        clipboard: {
          button: {
            text: '{{lastname_applicant}}',
            icon: { name: 'icon-copy', height: 14, width: 14 },
          },
          badge: {
            content: {
              text: 'Copied!',
              color: 'dark',
            },
          },
        },
      },
    },
    {
      label: {
        text: 'First name of agent',
      },
      value: {
        clipboard: {
          button: {
            text: '{{firstname_agent}}',
            icon: { name: 'icon-copy', height: 14, width: 14 },
          },
          badge: {
            content: {
              text: 'Copied!',
              color: 'dark',
            },
          },
        },
      },
    },
    {
      label: {
        text: 'Family name of agent',
      },
      value: {
        clipboard: {
          button: {
            text: '{{lastname_agent}}',
            icon: { name: 'icon-copy', height: 14, width: 14 },
          },
          badge: {
            content: {
              text: 'Copied!',
              color: 'dark',
            },
          },
        },
      },
    },
    {
      label: {
        text: 'Last 4 digits pf phone number for TOTP',
      },
      value: {
        clipboard: {
          button: {
            text: '{{phone_number}}',
            icon: { name: 'icon-copy', height: 14, width: 14 },
          },
          badge: {
            content: {
              text: 'Copied!',
              color: 'dark',
            },
          },
        },
      },
    },
    {
      label: {
        text: 'Message of applicant',
      },
      value: {
        clipboard: {
          button: {
            text: '{{message_body}}',
            icon: { name: 'icon-copy', height: 14, width: 14 },
          },
          badge: {
            content: {
              text: 'Copied!',
              color: 'dark',
            },
          },
        },
      },
    },
    {
      label: {
        text: 'Password reset link',
      },
      value: {
        clipboard: {
          button: {
            text: '{{password_reset_link}}',
            icon: { name: 'icon-copy', height: 14, width: 14 },
          },
          badge: {
            content: {
              text: 'Copied!',
              color: 'dark',
            },
          },
        },
      },
    },
    {
      label: {
        text: 'Address',
      },
      value: {
        clipboard: {
          button: {
            text: '{{address}}',
            icon: { name: 'icon-copy', height: 14, width: 14 },
          },
          badge: {
            content: {
              text: 'Copied!',
              color: 'dark',
            },
          },
        },
      },
    },
    {
      label: {
        text: 'Age',
      },
      value: {
        clipboard: {
          button: {
            text: '{{age}}',
            icon: { name: 'icon-copy', height: 14, width: 14 },
          },
          badge: {
            content: {
              text: 'Copied!',
              color: 'dark',
            },
          },
        },
      },
    },
  ],
  hideText: 'Hide all',
  showText: 'Show all',
};

export const WithShowAmount = () => <Tags showAmount={4} {...Default.args} />;

export const WithoutButton = () => <Tags button={false} {...Default.args} />;
