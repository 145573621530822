import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Flex,
  Title,
  Content,
  Button,
  Badge,
  Tooltip,
} from '@remark/components';
import styles from './section.module.css';

const Section = ({ id, title, description, action, label }) => (
  <div id={id}>
    <Container color='stable-100' full className={styles._self}>
      <Flex between gutter={false}>
        <div>
          <Flex gutter={false} middle>
            {title && <Title size="h5" {...title} />}
            {label &&
              <div className={styles.label}>
                <Badge
                  size="sublabel"
                  color="stable-700"
                  content={{
                    // convert 'en' to 'EN', but not 'All languages'
                    text: label.length == 2 ? label.toUpperCase() : label,
                    size: 'sublabel',
                    color: 'stable-900'
                  }}
                />
              </div>
            }
          </Flex>
          {description && <Content color="stable-900" size="label" {...description} /> }
        </div>
        {action && action.tooltip && (
          <Tooltip action={action} {...action.tooltip} />
        )}
        {action && !action.tooltip && <Button {...action} />}
      </Flex>
    </Container>
  </div>
);

Section.propTypes = {
  title: PropTypes.shape(Title.propTypes),
  description: PropTypes.shape(Content.propTypes),
  action: PropTypes.shape(Button.propTypes),
  label: PropTypes.string
};

Section.defaultProps = {
  title: null,
  description: null,
  action: null,
  label: null
};

// Needed for Storybook
Section.displayName = 'Section';

export default Section;
