import React from 'react';
import PropTypes from 'prop-types';
import { Button, Container } from '@remark/components';
import styles from './tabs.module.css';

const Tabs = ({ items }) => (
  <Container full className={styles._wrapper}>
    {items && (
      <div className={styles._items}>
        {items &&
          items.map((item, index) => (
            <div
              className={
                item.active ? styles._active_tab_wrap : styles._tab_wrap
              }
              key={index}
            >
              <Button
                className={styles._tab}
                link
                textColor={item.active ? 'brave-500' : 'stable-900'}
                customData={{ 'data-turbolinks-persist-scroll': true }}
                {...item}
              />
            </div>
          ))}
      </div>
    )}
  </Container>
);

Tabs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
};

Tabs.defaultProps = {
  items: null,
};

// Needed for Storybook
Tabs.displayName = 'Tabs';

export default Tabs;
