import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@remark/components';
import styles from './top.module.css';

const Top = ({ text }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);

    listenToScroll();

    return function cleanup() {
      window.removeEventListener('scroll', listenToScroll);
    };
  });

  const listenToScroll = () => {
    if (window.pageYOffset > 50) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  };

  return (
    <div
      className={
        enabled
          ? [styles.wrapper, styles.active].join(' ')
          : styles.initialWrapper
      }
    >
      <Button
        text={i18n.t('back_to_top')}
        action='#top'
        data={{ turbolinks: false }}
        outline
        color='brave-500'
        icon={{
          name: 'icon-arrow-top',
          width: 14,
          height: 14,
        }}
        size='m'
      />
    </div>
  );
};

// Needed for Storybook
Top.displayName = 'Top';

export default Top;
