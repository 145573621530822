import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Flex,
  Cell,
  Title,
  Content,
  Icon,
  Button,
  Breadcrumbs,
} from '@remark/components';
import styles from './menu.module.css';

const Menu = ({
  title,
  description,
  descriptionIcon,
  actions,
  breadcrumbs,
}) => (
  <Container full className={styles._wrapper}>
    {(title || description || breadcrumbs) && (
      <Flex gutter={false}>
        <Cell>
          <div className={styles.heading}>
            {breadcrumbs && (
              <div className={styles.innerHeading}>
                <Breadcrumbs className={styles.breadcrumbs} {...breadcrumbs} />
                <Icon
                  className={styles.breadcrumbsIcon}
                  name={'chevron-right-thin'}
                  color={'brave-500'}
                  width={9}
                />
              </div>
            )}
            {title && <Title size={'h2'} className={styles.title} {...title} />}
          </div>
          {description && (
            <div className={styles.description}>
              <Icon
                className={styles.icon}
                name={descriptionIcon}
                width={15}
                height={15}
              />
              <Content {...description} />
            </div>
          )}
          <div className={styles.actions}>
            {actions &&
              actions.map((item, index) => {
                if (item.text) {
                  return (
                    <Button className={styles.button} key={index} {...item} />
                  );
                }
              })}
          </div>
        </Cell>
      </Flex>
    )}
  </Container>
);
Menu.propTypes = {
  title: PropTypes.shape(Title.propTypes),
  description: PropTypes.shape(Content.propTypes),
  actions: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
  descriptionIcon: PropTypes.string,
  breadcrumbs: PropTypes.shape(Breadcrumbs.propTypes),
};

Menu.defaultProps = {
  title: null,
  description: null,
  descriptionIcon: '',
  actions: null,
  breadcrumbs: null,
};

// Needed for Storybook
Menu.displayName = 'Menu';

export default Menu;
