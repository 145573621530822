import React from 'react';
import { Icon, Content } from '@remark/components';
import markdown from './notes.md';

const icons = require('./icons.json');

export default {
  title: 'Examples/Icons',
  parameters: {
    notes: { markdown },
  },
};
const Template = (args) => (
  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
    {icons.children.length > 0 &&
      icons.children.map((icon, index) => {
        const name = icon.name.replace('.svg', '');
        return (
          <div key={index}>
            <div
              style={{
                width: 100,
                height: 100,
                margin: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid rgba(var(--color-stable-500), 1)',
              }}
            >
              <svg width='54' viewBox='0 0 1000 500' fill='none'>
                <use href={`#symbol-${name}`} />
              </svg>
            </div>
            <Content text={name} align={'center'} size={'label'} />
          </div>
        );
      })}
  </div>
);
export const Default = Template.bind({});
