import React from 'react';
import PropTypes from 'prop-types';
import { Element } from '@remark/components';
import styles from './textEditor.module.css';

const TextEditor = ({ value, fields, editMode }) => {
  let toolbar = {
    options: ['inline', 'list', 'link', 'history'],
    inline: {
      options: ['bold', 'italic', 'underline'],
    },
    list: {
      options: ['ordered', 'unordered'],
    },
    link: {
      options: ['link'],
    },
  };

  // lame fix for showing heading options on '/settings/pages'
  // https://remarkgroup.atlassian.net/browse/VPD-1022
  if (window.location.href.includes('pages')) {
    toolbar = {
      ...toolbar,
      options: ['inline', 'list', 'link', 'blockType', 'history'],
      blockType: {
        inDropdown: false,
        options: ['Normal', 'H2', 'H3'],
      },
    };
  }

  return (
    <div>
      {!editMode && !(value.text[0] == null || value.text[0] == '') && (
        <Element
          className={styles._readonly}
          value={value.text[0]}
          initialContent={value.text[0]}
          type='text-editor'
          toolbarHidden
          readOnly
        />
      )}
      {editMode &&
        fields &&
        fields.length > 0 &&
        fields.map((field, index) => (
          <div className={styles._inputblock} key={index}>
            <Element
              className={[field.className, styles._field].join(' ')}
              initialContent={field.type === 'text-editor' && value.text[index]}
              toolbar={toolbar}
              maxHeight
              {...field}
            />
          </div>
        ))}
    </div>
  );
};

TextEditor.propTypes = {
  value: PropTypes.shape({
    text: PropTypes.arrayOf(PropTypes.string),
    color: PropTypes.string,
  }),
  fields: PropTypes.arrayOf(PropTypes.shape(Element.propTypes)),
  editMode: PropTypes.bool,
};

TextEditor.defaultProps = {
  value: null,
  fields: null,
  editMode: false,
};

// Needed for Storybook
TextEditor.displayName = 'TextEditor';

export default TextEditor;
