import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Title,
  Switcher,
  Tooltip,
  Button,
} from '@remark/components';
import styles from './page.module.css';

const Page = ({ title, actions, languages }) => (
  <Container color='stable-100' full className={styles._self}>
    {title && <div className={styles.title}><Title size="h3" text={title} /></div>}
    {languages && <div className={styles.switcher}><Switcher items={languages} /></div>}
    {actions && (
      <div className={styles.actions}>
        {actions.map((button, key) => (
          <div key={key} className={styles.action}>
            {button.tooltip && button.disabled && (
              <Tooltip action={button} {...button.tooltip} />
            )}
            {(!button.tooltip || !button.disabled) && <Button {...button} />}
          </div>
        ))}
      </div>
    )}
  </Container>
);

Page.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.array,
  languages: PropTypes.array
};

Page.defaultProps = {
  title: null,
  languages: null,
  actions: null,
};

// Needed for Storybook
Page.displayName = 'Page';

export default Page;
