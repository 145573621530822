import React from 'react';
import Setting from '.';

export default {
  title: 'Components/Setting',
  component: Setting,
};

const Template = (args) => <Setting {...args} />;

export const Default = Template.bind({});
Default.args = {
  title: {
    text: 'Company name',
    size: 'h5',
    color: 'dark',
  },
  description: {
    size: 'label',
    color: 'stable-900',
    text: 'Last updated 2 March 2021 by Simon Visor',
  },
  value: {
    size: 'body',
    color: 'dark',
    text: 'Acme assurances',
  },
  fields: [
    {
      name: 'rails_name',
      value: 'Acme assurances',
      type: 'text',
    },
  ],
  emptyValue: 'Add this setting',
};

export const WithError = () => {
  const args = {
    ...Default.args,
    fields: [
      {
        name: 'rails_name',
        value: 'Acme assurances',
        type: 'text',
        errors: ['This is an error'],
      },
    ],
  };
  return <Setting {...args} />;
};

export const AsTextarea = () => {
  const args = {
    ...Default.args,
    fields: [
      {
        name: 'rails_name',
        value: 'Acme assurances',
        type: 'textarea',
      },
    ],
  };
  return <Setting {...args} />;
};

export const AsSelect = () => {
  const args = {
    ...Default.args,
    value: {
      size: 'body',
      color: 'dark',
      text: 'Chocolate',
    },
    fields: [
      {
        type: 'select',
        options: [
          { value: 'chocolate', label: 'Chocolate', checked: true },
          { value: 'strawberry', label: 'Strawberry' },
          { value: 'vanilla', label: 'Vanilla' },
        ],
      },
    ],
  };
  return <Setting {...args} />;
};

export const AsRadioGroup = () => {
  const args = {
    ...Default.args,
    value: {
      size: 'body',
      color: 'dark',
      text: 'Yes',
    },
    fields: [
      {
        type: 'radio',
        column: true,
        options: [
          {
            checked: true,
            label: 'Yes',
            sublabel: 'The opposite of no',
            name: 'claim[gender]',
            value: 'male',
          },
          {
            checked: false,
            label: 'No',
            name: 'claim[gender]',
            value: 'male',
          },
        ],
      },
    ],
  };
  return <Setting {...args} />;
};

export const AsPhone = () => {
  const args = {
    ...Default.args,
    fields: [
      {
        type: 'phone',
        defaultCountry: 'fr',
        region: 'europe',
      },
    ],
  };
  return <Setting {...args} />;
};

export const AsUpload = () => {
  const args = {
    ...Default.args,
    value: {
      text: 'file',
    },
    fields: [
      {
        type: 'file',
      },
    ],
  };
  return <Setting {...args} />;
};

export const AsTextEditor = () => {
  const args = {
    ...Default.args,
    value: {
      text: ['test'],
    },
    fields: [
      {
        type: 'text-editor',
      },
    ],
  };
  return <Setting {...args} />;
};

export const WithinForm = () => (
  <form id='some_id' action='https://httpbin.org/' method='post'>
    <Setting {...Default.args} />
  </form>
);

export const MultipleFields = () => {
  const args = {
    ...Default.args,
    fields: [
      {
        name: '_name',
        label: 'Hidden input',
        type: 'hidden',
      },
      {
        name: 'name2',
        label: 'First Name',
        type: 'text',
      },
      {
        name: 'name3',
        label: 'Last Name',
        type: 'text',
      },
      {
        name: 'name4',
        label: 'Email',
        type: 'email',
      },
      {
        type: 'name5',
        defaultCountry: 'fr',
        region: 'europe',
        label: 'Phone',
      },
    ],
  };
  return <Setting {...args} />;
};

export const ExpandedEmailView = () => {
  const args = {
    ...Default.args,
    value: [
      {
        title: 'Status',
        text: 'Active',
      },
      {
        title: 'Subject',
        text: '{{nrc}} - ACME online medical questionnaire',
      },
      {
        title: 'Content',
        text: 'You have chosen to take out a contract for which your advisor has told you that it is necessary to complete an online medical questionnaire. For this, click on the link below in order to access your secure personal space: {{cta}} This link is valid for 15 days from receipt of this e-mail. Use of this secure space guarantees confidentiality medical information provided. To facilitate reconnection to your space at any time, you are advised to keep this message. Thank you for your trust.',
        mixed: true,
      },
    ],
    type: 'email',
  };
  return <Setting {...args} />;
};

export const EmptyValue = () => {
  const args = {
    ...Default.args,
    value: {
      text: [null],
    },
  };
  return <Setting {...args} />;
};
